import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    LinearProgress
} from '@mui/material';
import { useUserContext } from '../../utilities/context/userContext';
import SnackbarBase from '../SnackbarBase';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import GeneralModal from './GeneralModal';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { green } from '@mui/material/colors';
import parse, { domToReact } from 'html-react-parser';

const CopyLinkBtn = ({ linkID }) => {
    const [isCopying, setIsCopying] = useState(false);
    const [IsCopied, setIsCopied] = useState(false);

    return (
        <Button
            sx={{ width: 150 }}
            disabled={isCopying}
            onClick={async () => {
                // handleDelete(jobData);
                // jobData._id
                setIsCopied(false);
                try {
                    setIsCopying(true);
                    await navigator.clipboard.writeText(
                        `nationalcourierexpress.com/careers/${linkID}`
                    );
                    setIsCopied(true);
                    setIsCopying(false);
                } catch (err) {
                    setIsCopying(false);
                    console.error('Failed to copy: ', err);
                }
            }}
            variant="outlined"
            // color='error'
            startIcon={
                IsCopied == true
                    ? <CheckIcon style={{ color: 'green' }} />
                    : isCopying == true
                      ? <CircularProgress
                            size={24}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px'
                            }}
                        />
                      : <ContentCopyIcon />
            }>
            Copy Link
        </Button>
    );
};

export default function Jobs() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [didError, setDidError] = useState(false);

    const authManager = useUserContext();
    const handleGetJobPosts = async () => {
        setLoading(true);
        try {
            const response = await fetch('/api/jobposts/', {
                method: 'Get', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `token ${authManager.authToken}`
                }
            });
            if (response.ok) {
                const result = await response.json();
                setUsers(result);
            } else {
                setDidError(true);
            }
        } catch (error) {
            setDidError(true);
            setLoading(false);
        }
        setLoading(false);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
            handleGetJobPosts();
        }, 600);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const options = {
        replace(domNode) {
            // let { attribs, children, name } = domNode
            if (!domNode.attribs) {
                return;
            }

            if (domNode.attribs.class !== '') {
                domNode.attribs.class = '';
                domNode.attribs.style = '';
                let thing = {
                    ...domNode,
                    attribs: { ...domNode.attribs, class: '', style: '' }
                };
                // debugger
                // let thing = `<${name}></${name}>`
                // // debugger
                // if (name == 'b') {
                //     return (
                //         <b>
                //             {domToReact(children, options)}
                //         </b>
                //     );
                // }
                return { ...thing };
                // return thing;
                // return domNode.name + {domToReact(children, options)} + domNode.name;
            }
        }
    };

    const handleDelete = async jobData => {
        setLoading(true);
        try {
            const response = await fetch(`/api/jobposts/${jobData._id}`, {
                method: 'delete', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `token ${authManager.authToken}`
                }
            });
            if (response.ok) {
                const result = await response.json();
                if (result.acknowledged === true) {
                    handleGetJobPosts();
                }
            } else {
                setDidError(true);
            }
        } catch (error) {
            setDidError(true);
            setLoading(false);
        }
        setLoading(false);
    };
    const formFields = [
        {
            lable: 'Title',
            name: 'title',
            value: '',
            type: 'text',
            validText: 'please enter the username'
        },
        {
            lable: 'Location',
            name: 'location',
            value: '',
            type: 'text',
            validText: 'please enter the first name'
        },

        {
            lable: 'State',
            name: 'state_select',
            value: 'GA',
            type: 'state_select',
            validText: 'please select a state'
        },

        {
            lable: 'Rate',
            name: 'rate',
            value: '',
            type: 'text',
            validText: 'please enter the last name'
        },
        {
            lable: 'Description',
            name: 'description',
            value: '',
            type: 'fancy_text',
            validText: 'please enter a valid email'
        }
    ];
    return (
        <React.Fragment>
            <SnackbarBase
                isOpen={didError}
                setIsOpen={setDidError}
                type={'error'}
                message={
                    'there was a problem retrieving job information. Please try again later'
                }
            />
            <Title>Job postings</Title>
            {loading ? <LinearProgress /> : null}
            <Divider />
            <Grid container>
                <Grid
                    item
                    xs={10}
                    style={{
                        display: 'flex',
                        gap: '1rem',
                        alignItems: 'center'
                    }}>
                    <Button
                        onClick={handleGetJobPosts}
                        variant="outlined"
                        sx={{ m: 2 }}
                        startIcon={<RefreshIcon />}>
                        refresh
                    </Button>

                    <GeneralModal
                        IconButton={() => <EditIcon />}
                        formFields={formFields}
                        title="Create job post"
                        handleSend={async data => {
                            let url = '/api/jobposts/create';
                            const response = await fetch(url, {
                                method: 'POST', // or 'PUT'
                                headers: {
                                    'Content-Type': 'application/json',
                                    authorization: `token ${authManager.authToken}`
                                },
                                body: JSON.stringify(data)
                            });

                            if (response.ok) {
                                const result = await response.json();
                                handleGetJobPosts();
                                console.log('Success:', result);
                                return response;
                            }
                            if (!response.ok) {
                                return response;
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>Rate</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(jobData =>
                        <TableRow key={jobData._id}>
                            <TableCell>
                                {jobData.title}
                            </TableCell>
                            <TableCell>
                                {jobData.location}
                            </TableCell>
                            <TableCell>
                                {parse(
                                    jobData.description.substring(0, 230) +
                                        '...',
                                    options
                                )}
                            </TableCell>
                            <TableCell>
                                {jobData.state_select}
                            </TableCell>
                            <TableCell>
                                {jobData.rate}
                            </TableCell>
                            <TableCell>
                                {new Date(jobData.date).toDateString()}
                            </TableCell>
                            <TableCell align="right">
                                <CopyLinkBtn linkID={jobData._id} />
                                <GeneralModal
                                    IconButton={() => <EditIcon />}
                                    editMode={true}
                                    formFields={[
                                        {
                                            lable: 'Title',
                                            name: 'title',
                                            value: jobData.title,
                                            type: 'text',
                                            validText:
                                                'please enter the username'
                                        },
                                        {
                                            lable: 'Location',
                                            name: 'location',
                                            value: jobData.location,
                                            type: 'text',
                                            validText:
                                                'please enter the first name'
                                        },
                                        {
                                            lable: 'State',
                                            name: 'state_select',
                                            value: jobData.state_select,
                                            type: 'state_select',
                                            validText: 'please select a state'
                                        },
                                        {
                                            lable: 'Rate',
                                            name: 'rate',
                                            value: jobData.rate,
                                            type: 'text',
                                            validText:
                                                'please enter the last name'
                                        },
                                        {
                                            lable: 'Description',
                                            name: 'description',
                                            value: jobData.description,
                                            type: 'fancy_text',
                                            validText:
                                                'please enter a valid email'
                                        }
                                    ]}
                                    title="edit"
                                    handleSend={async data => {
                                        debugger;
                                        // return await handlePasswordSubmit(data, user);

                                        const response = await fetch(
                                            `/api/jobposts/${jobData._id}`,
                                            {
                                                method: 'PUT',
                                                headers: {
                                                    'Content-Type':
                                                        'application/json',
                                                    authorization: `token ${authManager.authToken}`
                                                },
                                                body: JSON.stringify(data)
                                            }
                                        );
                                        debugger;
                                        if (!response.ok) {
                                            return response;
                                        }
                                        // const resData = await response.json();
                                        // // authManager.handleLogin(resData);
                                        handleGetJobPosts();
                                        // debugger;
                                        return response;

                                        // } catch (error) {
                                        //   debugger
                                        //     // authManager.handleLoginOut();
                                        //     console.log(error);
                                        // }
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        handleDelete(jobData);
                                    }}
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteIcon />}>
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}
